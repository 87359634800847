// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
	apiKey: 'AIzaSyCvNqBI2CS07qtN74zdZtSa-a7to2TUGz8',
	authDomain: 'wrec-er.firebaseapp.com',
	projectId: 'wrec-er',
	storageBucket: 'wrec-er.appspot.com',
	messagingSenderId: '257757091675',
	appId: '1:257757091675:web:2271d62abfeb837d1d2c7e',
	measurementId: 'G-FTNV0VW653'
};
