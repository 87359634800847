// eslint-disable-next-line import/no-anonymous-default-export
export default {

    GET_ALL_BLOGS_METADATA_REQUEST: 'GET_ALL_BLOGS_METADATA_REQUEST',
    GET_ALL_BLOGS_METADATA_SUCCESS: 'GET_ALL_BLOGS_METADATA_SUCCESS',
    GET_ALL_BLOGS_METADATA_FAILURE: 'GET_ALL_BLOGS_METADATA_FAILURE',

    GET_BLOG_FILES_REQUEST: 'GET_BLOG_FILES_REQUEST',
    GET_BLOG_FILES_SUCCESS: 'GET_BLOG_FILES_SUCCESS',
    GET_BLOG_FILES_FAILURE: 'GET_BLOG_FILES_FAILURE'
};